import Swiper, { Pagination } from 'swiper';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { transitions, transitionTimeline } from '../lib/animations';
import Vivus from 'vivus';
import { scrolling } from '../lib/scrolling';
import { DefaultView } from './DefaultView';

class LandingView extends DefaultView {
  namespace = 'landing';

  beforeEnter(data) {
    super.beforeEnter(data);

    const container = data.next.container;
    this.container = container;

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    this.hero = container.querySelector('.landing-hero');
    this.heading = container.querySelector('.landing-hero__heading');
    this.texts = container.querySelectorAll('.landing-hero__subheading');
    this.addr = container.querySelector('.landing-hero__address');
    this.features = container.querySelector('.landing-features');
    this.featuresBg = container.querySelector('.landing-features__bg');
    this.featuresList = container.querySelector('.landing-features__list');
    this.length = container.querySelector('.landing-numbers');
    this.config = container.querySelector('.landing-hero__config');

    this.numbers = [
      ...container.querySelectorAll('.landing-numbers__num svg'),
    ].map(
      (element) =>
        new Vivus(element, { duration: 50, start: 'manual' }, () => {})
    );

    this.snapAnimations = {
      init: () => {
        const tl = gsap.timeline();
        tl.add(transitionTimeline(transitions.fadeUp, this.heading, 0.75));
        tl.add(
          transitionTimeline(
            transitions.fadeUp,
            '.landing-hero__subheading--hero',
            0.75
          ),
          '-=0.25'
        );
        tl.add(transitionTimeline(transitions.fade, this.addr, 1));

        gsap.to(this.featuresBg, {
          clipPath: `polygon(${
            window.innerWidth + this.featRectangleOffset(this.featuresBg)
          }px 0, 100% 0, 100% 100%, 100% 100%)`,
        });
        gsap.to(this.featuresList, { autoAlpha: 0 });
        gsap.to('.landing-hero__subheading--features', { autoAlpha: 0 });
      },
      features: () => {
        gsap.to(this.heading, { autoAlpha: 0 });
        gsap.to('.landing-hero__subheading--hero', { autoAlpha: 0 });
        gsap.to('.landing-hero__subheading--features', { autoAlpha: 1 });
        gsap.to(this.config, { autoAlpha: 0 });
        this.hero.classList.remove('landing-hero--no-bg');
        gsap.to(this.addr, { autoAlpha: 0 });
        gsap.to(this.features, { autoAlpha: 1 });

        const tl = gsap.timeline();
        tl.fromTo(
          this.featuresBg,
          {
            clipPath: `polygon(${
              window.innerWidth + this.featRectangleOffset(this.featuresBg)
            }px 0, 100% 0, 100% 100%, 100% 100%)`,
          },
          {
            clipPath: `polygon(${this.featRectangleOffset(
              this.featuresBg
            )}px 0, 100% 0, 100% 100%, 0% 100%)`,
            duration: 0.75,
          }
        );
        tl.to(this.featuresList, { autoAlpha: 1 }, '-=0.4');

        gsap.to(this.length, { autoAlpha: 0 });
        this.numbers.forEach((num) => num.reset());
      },
      length: () => {
        this.hero.hidden = false;
        this.hero.classList.add('landing-hero--no-bg');
        this.texts.forEach((text, i) => {
          gsap.to(text, { autoAlpha: 0 });
        });

        this.numbers.forEach((num) => num.play(1, () => {}));

        const tl = gsap.timeline();
        container.querySelectorAll('.landing-numbers__text').forEach((text) => {
          tl.add(transitionTimeline(transitions.fade, text, 1), '=-0.5');
        });

        gsap.to(this.config, { autoAlpha: 1 });
        gsap.to(this.addr, { autoAlpha: 0 });
        gsap.to(this.features, { autoAlpha: 0 });
        gsap.to(this.featuresList, { autoAlpha: 0 });
        gsap.to(this.length, { autoAlpha: 1 });
      },
      tracks: () => {
        this.hero.hidden = true;
        this.numbers.forEach((num) => num.reset());
      },
    };


    this.triggers = [];
    setTimeout(() => {
      ScrollTrigger.matchMedia({
        '(max-width: 767px)': this.animateMobile,
        '(min-width: 768px)': this.animateDesktop,
      });
    }, 100);

    new Swiper('.landing-mobile-features__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  beforeLeave(data) {
    super.beforeLeave(data);

    this.triggers.forEach((trigger) => trigger && trigger.kill());
    this.numbers.forEach((num) => num && num.destroy());
  }

  deg2rad(angle) {
    return (angle * Math.PI) / 180;
  }

  rightTriangleAdjacentSide(angle, opposedSite) {
    return opposedSite / Math.tan(this.deg2rad(angle));
  }

  featRectangleOffset(element) {
    return this.rightTriangleAdjacentSide(48, element.clientHeight);
  }

  animateMobile = () => {
    gsap.set('.landing-contact__phone img', { y: 0 });
    gsap.set('.contact-form__skewed-rectangle', { clipPath: null });
  };

  animateDesktop = () => {
    const motoTimeLine = gsap
      .timeline()
      .fromTo(
        '.landing-teach__moto-box',
        {
          clipPath: 'polygon(0 0, 0% 0%, 0% 100%, 0 45%)',
        },
        {
          clipPath: 'polygon(0 0, 100% 55%, 100% 100%, 0 45%)',
          duration: 0.5,
        }
      )
      .fromTo(
        '.landing-teach__moto img',
        {
          opacity: 0,
          x: '-40%',
          y: '10%',
        },
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 1,
        }
        //'-=0.75'
      );
    this.triggers.push(
      ScrollTrigger.create({
        trigger: this.container.querySelector('.landing-teach'),
        start: 'top top',
        animation: motoTimeLine,
      })
    );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.container.querySelector('.landing-contact__inner'),
          start: 'top bottom',
          //end: 'bottom top',
          scrub: 1,
        },
      })
      .fromTo('.landing-contact__phone img', { y: '100px' }, { y: '-100px' });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.container.querySelector('.landing-contact__inner'),
          start: 'top bottom-=200',
        },
      })
      .fromTo(
        '.landing-contact__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        }
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.container.querySelector('.landing-competition'),
          start: `top bottom-=200`,
        },
      })
      .add(
        transitionTimeline('.landing-competition__bg-img', transitions.fade)
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.container.querySelector('.landing-competition__prize'),
          start: 'top bottom-=100',
        },
      })
      .fromTo(
        '.landing-competition__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        }
      );

    const trigger = ScrollTrigger.create({
      trigger: this.container.querySelector('.landing-competition'),
      start: 'top bottom-=200',

      onEnter: (e) => {
        this.container
          .querySelector('.landing-competition__bg')
          .classList.add('is-triggered');
      },
    });
    this.triggers.push(trigger);
  };
}

export { LandingView };
