import A11yDialog from 'a11y-dialog';
import Vivus from 'vivus';
import { submitForm } from '../lib/submitForm';

export class FormDialog {
  constructor(id) {
    const element = document.getElementById(id);
    if (!element) return;

    const form = element.querySelector('form');
    if (!form) return;

    const iconElement = form.querySelector('.form__success-icon');
    const icon = iconElement
      ? new Vivus(iconElement, { duration: 100, start: 'manual' }, () => {})
      : null;

    this.dialog = new A11yDialog(element);

    submitForm(form, {
      onSubmit: () => {
        form.classList.add('is-success');
        if (icon) {
          icon.play(1, () => {});
        }

        setTimeout(() => this.dialog.hide(), 3000);
      },
    });
  }

  close() {
    if (this.dialog) this.dialog.hide();
  }

  open() {
    if (this.dialog) this.dialog.show();
  }
}
