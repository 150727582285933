import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from '../lib/SplitText3';
import { transitions } from '../lib/animations';

class Animated {
  constructor(container = document) {
    gsap.registerPlugin(ScrollTrigger);

    const animated = [...container.querySelectorAll('[data-anim]')];

    this.triggers = [];
    animated.forEach((element) => {
      const anim = element.dataset.anim;
      const offset = element.dataset.animOffset || 50;
      const duration = element.dataset.animDuration || 1;
      const delay = element.dataset.animDelay || 0;

      if (!(anim in transitions)) {
        return;
      }

      const animation = transitions[anim];
      animation.to.duration = duration;

      const tl = gsap.timeline();

      if (delay > 0) {
        tl.set({}, {}, `+=${delay}`);
      }

      tl.fromTo(element, animation.from, animation.to);

      const trigger = ScrollTrigger.create({
        trigger: element,
        start: `top bottom-=${offset}`,
        animation: tl,
      });
      if (trigger) this.triggers.push(trigger);
    });

    container.querySelectorAll('[data-anim="heading"]').forEach((heading) => {
      const lines = new SplitText(heading, { type: 'words,lines', tag: 'span' })
        .lines;

      const tl = gsap.timeline();
      lines.forEach((line) => {
        tl.fromTo(
          line.querySelectorAll('*'),
          { y: '100%', opacity: 0 },

          { y: 0, opacity: 1, ease: 'power1.inOut', duration: 0.6 },
          '<0.1'
        );
      });

      const trigger = ScrollTrigger.create({
        trigger: heading,
        start: `top bottom-=200`,
        animation: tl,
      });
      if (trigger) this.triggers.push(trigger);
    });
  }

  destroy() {
    this.triggers.forEach((trigger) => trigger && trigger.kill());
  }
}

export { Animated };
