import Vivus from 'vivus';
import { onImageLoad } from '../lib/onImageLoad';
import { scrolling } from '../lib/scrolling';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export class DefaultView {
  namespace = 'default';

  snapAnimations = {};

  beforeEnter(data) {
    this.container = data.next.container;

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    this.numbers = [
      ...this.container.querySelectorAll('.numbers__num:not(.is-manual) svg'),
    ].map(
      (element) =>
        new Vivus(element, { duration: 50, start: 'inViewport' }, () => {})
    );


    this.container
      .querySelectorAll('.image-fade-onload')
      .forEach((image) =>
        onImageLoad(image).then(() => image.classList.add('is-loaded'))
      );

    requestAnimationFrame(this.initAnimations);
    requestAnimationFrame(() =>
      ScrollTrigger.matchMedia({
        '(min-width: 768px)': this.animateSnap,
      })
    );
  }

  beforeLeave(data) {
    this.numbers.forEach((num) => num && num.destroy());
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};
  animateDesktop = () => {};

  animateSnap = () => {
    const sections = this.container.querySelectorAll(
      '.page__main--has-snap .snap-section'
    );

    sections.forEach((section) => {
      ScrollTrigger.create({
        //markers: true,
        trigger: section,
        start: 'top bottom-=1',
        end: 'bottom top+=1',
        onEnter: () => this.goToSnapSection(section, section.dataset.name),
        onEnterBack: () => this.goToSnapSection(section, section.dataset.name),
      });
    });
  };

  goToSnapSection = (section, name) => {
    if (scrolling.enabled) {
      // skip if a scroll tween is in progress
      scrolling.disable();
      gsap.to(window, {
        scrollTo: { y: section, autoKill: false },
        onComplete: () => {
          const offset = ScrollToPlugin.getOffset(section);
          window.scrollTo(0, offset.y);
          scrolling.enable();
        },
        duration: 0.75,
      });

      if (name in this.snapAnimations) {
        this.snapAnimations[name]();
      }
    }
  };
}
