import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transitionTimeline, transitions } from '../lib/animations';
import { DefaultView } from './DefaultView';
import { FormDialog } from '../components/FormDialog';
import { ServiceDialog } from '../components/ServiceDialog';

export class ServicesView extends DefaultView {
  namespace = 'services';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    this.services = this.container.querySelectorAll('.main-services__link');
    this.images = this.container.querySelectorAll('.main-services__image');

    this.services.forEach((service, idx) => {
      service.addEventListener('mouseover', () =>
        this.highlightService(service, idx)
      );
      service.addEventListener('focus', () =>
        this.highlightService(service, idx)
      );
    });

    const storageDialog = new FormDialog('storage-dialog');
    this.container
      .querySelectorAll('.js-services__storage-button')
      .forEach((btn) =>
        btn.addEventListener('click', () => storageDialog.open())
      );

    const servicesDialog = new ServiceDialog('services-dialog');
    this.container
      .querySelectorAll('.js-services__services-button')
      .forEach((btn) =>
        btn.addEventListener('click', () => servicesDialog.open())
      );

    const bikeDialog = new ServiceDialog('bike-dialog');
    this.container
      .querySelectorAll('.js-services__bike-button')
      .forEach((btn) => btn.addEventListener('click', () => bikeDialog.open()));

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  highlightService = (service, idx) => {
    this.services.forEach((service) => service.classList.remove('is-active'));
    service.classList.add('is-active');

    this.images.forEach((img, imgIdx) => {
      if (imgIdx === idx) {
        gsap.to(img, { autoAlpha: 1 });
      } else {
        gsap.to(img, { autoAlpha: 0 });
      }
    });
  };

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {
    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.main-services__heading'))
      .add(
        transitionTimeline(transitions.fade, '.main-services__list'),
        '-=0.5'
      );
  };
}
