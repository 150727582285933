import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  transitionTimeline,
  transitions,
  multilineTransitionTimeline,
} from '../lib/animations';
import { DefaultView } from './DefaultView';
import Swiper, { Lazy, Pagination } from 'swiper';
import A11yDialog from 'a11y-dialog';
import { FormDialog } from '../components/FormDialog';

export class EducationView extends DefaultView {
  namespace = 'education';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    const adultDialog = new FormDialog('adult-dialog');
    const childrenDialog = new FormDialog('children-dialog');
    this.container
      .querySelector('.js-education-training-card__adult-btn')
      .addEventListener('click', () => adultDialog.open());
    this.container
      .querySelector('.js-education-training-card__children-btn')
      .addEventListener('click', () => childrenDialog.open());

    this.motoButtons = this.container.querySelectorAll('.bike-rent__bike');
    this.motoTitles = this.container.querySelectorAll('.bike-rent__img-title');
    this.motoImages = this.container.querySelectorAll('.bike-rent__img');

    this.motoButtons.forEach((motoItem, idx) => {
      motoItem.addEventListener('mouseover', () =>
        this.highlightMoto(motoItem, idx)
      );
      motoItem.addEventListener('focus', () =>
        this.highlightMoto(motoItem, idx)
      );

      const dialog = new FormDialog(`rent-dialog-${idx}`);
      motoItem.addEventListener('click', () => dialog.open());
    });

    new Swiper('.education-mobile-disciplines__swiper', {
      modules: [Pagination, Lazy],
      slidesPerView: 'auto',
      spaceBetween: 40,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    new Swiper('.bike-rent-mobile__swiper', {
      modules: [Pagination, Lazy],
      slidesPerView: 'auto',
      spaceBetween: 40,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  highlightMoto = (motoItem, selectedIdx) => {
    this.motoButtons.forEach((btn) => btn.classList.remove('is-active'));
    motoItem.classList.add('is-active');

    this.motoImages.forEach((item, idx) => {
      if (idx === selectedIdx) {
        gsap.to(item, { autoAlpha: 1 });
      } else {
        gsap.to(item, { autoAlpha: 0 });
      }
    });

    this.motoTitles.forEach((item, idx) => {
      if (idx === selectedIdx) {
        gsap.to(item, { autoAlpha: 1 });
      } else {
        gsap.to(item, { autoAlpha: 0 });
      }
    });
  };

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {
    gsap
      .timeline()
      .add(multilineTransitionTimeline('.education-hero__heading'))
      .add(
        transitionTimeline(transitions.fade, '.education-hero__text'),
        '-=0.5'
      )
      .fromTo(
        '.education-training__card--adult',
        { x: -100, autoAlpha: 0 },
        { x: 0, autoAlpha: 1 },
        '-=0.5'
      )
      .fromTo(
        '.education-training__card--children',
        { x: 100, autoAlpha: 0 },
        { x: 0, autoAlpha: 1 },
        '<'
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.bike-rent__img-bg',
          start: `top bottom-=200`,
        },
      })
      .fromTo(
        '.bike-rent__img-bg',
        { clipPath: 'polygon(0 0, 0% 0, 0% 100%, 0 100%)' },
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          ease: 'power1.inOut',
        }
      )
      .fromTo(
        '.bike-rent__img-item:nth-child(1) .bike-rent__img-title',
        { autoAlpha: 0 },
        { autoAlpha: 1 }
      )
      .fromTo(
        '.bike-rent__img-item:nth-child(1) .bike-rent__img',
        { x: -100, autoAlpha: 0 },
        { x: 0, autoAlpha: 1 },
        '<'
      );
  };
}
