import { gsap } from 'gsap';
import { SplitText } from './SplitText3';

const transitions = {
  fade: {
    from: {
      autoAlpha: 0,
    },
    to: {
      autoAlpha: 1,
    },
  },
  fadeUp: {
    from: {
      autoAlpha: 0,
      y: '50%',
    },
    to: {
      autoAlpha: 1,
      y: 0,
      ease: 'power1.inOut',
    },
  },
};

function transition(transition, targets, duration = 1, delay = 0) {
  return gsap.fromTo(targets, transition.from, {
    ...transition.to,
    duration,
    delay,
  });
}

function transitionTimeline(
  transition,
  targets,
  duration = 1,
  position = null
) {
  return gsap.timeline().fromTo(targets, transition.from, {
    ...transition.to,
    duration,
    position,
  });
}

function multilineTransitionTimeline(target) {
  const lines = new SplitText(target, { type: 'words,lines', tag: 'span' })
    .lines;

  const tl = gsap.timeline();
  lines.forEach((line) => {
    tl.fromTo(
      line.querySelectorAll('*'),
      { y: '100%', opacity: 0 },

      { y: 0, opacity: 1, ease: 'power1.inOut', duration: 0.6 },
      '<0.1'
    );
  });

  return tl;
}

export { transition, transitionTimeline, multilineTransitionTimeline, transitions };
