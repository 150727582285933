function onImageLoad(imageElement) {
  return new Promise((resolve) => {
    if (imageElement.complete) {
      resolve();
    } else {
      imageElement.addEventListener('load', resolve);
      imageElement.addEventListener('error', resolve);
    }
  });
}

export { onImageLoad };
