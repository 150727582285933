import Vivus from 'vivus';
import { gsap } from 'gsap';
import { transitions, transitionTimeline } from '../lib/animations';
import Swiper, { Pagination, Navigation, EffectFade, Controller } from 'swiper';
import { DefaultView } from './DefaultView';

class TrackView extends DefaultView {
  namespace = 'track';

  beforeEnter(data) {
    super.beforeEnter(data);

    const container = data.next.container;
    this.numbers = [...container.querySelectorAll('.numbers__num svg')].map(
      (element) =>
        new Vivus(element, { duration: 50, start: 'inViewport' }, () => {})
    );

    new Swiper('.mobile-benefits__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 15,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    new Swiper('.benefits__swiper .swiper', {
      modules: [Navigation, Pagination, EffectFade, Controller],
      loop: true,
      spaceBetween: 30,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },
    });

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.track-hero__heading'))
      .add(transitionTimeline(transitions.fade, '.track-hero__subheading'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__text'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__contact'), '<')
      .fromTo(
        '.track-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      )
      .add(transitionTimeline(transitions.fade, '.track-hero__cert'), '<');
  }

  beforeLeave(data) {
    super.beforeLeave(data);

    this.numbers.forEach((num) => num && num.destroy());
  }
}

export { TrackView };
