import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DefaultView } from './DefaultView';

export class ContactsView extends DefaultView {
  namespace = 'contacts';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    this.footer = document.querySelector('.page__footer');
    this.footer.hidden = true;

    gsap.timeline().fromTo(
      this.container.querySelectorAll('.input-group__label'),
      { clipPath: 'polygon(0 0, 0% 0%, 0% 100%, 0% 100%)' },
      {
        clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)',
        duration: 0.6,
        ease: 'power1.inOut',
      }
    );

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
    this.footer.hidden = false;
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {};
}
