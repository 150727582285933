import { gsap } from 'gsap';
import Vivus from 'vivus';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import {
  transitionTimeline,
  transitions,
  multilineTransitionTimeline,
} from '../lib/animations';
import { DefaultView } from './DefaultView';
import Swiper, { Lazy, Pagination } from 'swiper';

export class GazeboView extends DefaultView {
  namespace = 'gazebo';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    new Swiper('.gazebo-mobile-commodities__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    new Vivus(
      this.container.querySelector('.gazebo-rent__price-num svg'),
      { duration: 50, start: 'inViewport' },
      () => {}
    );

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.hero__heading'))
      .add(transitionTimeline(transitions.fade, '.hero__subheading'), '-=0.5')
      .add(transitionTimeline(transitions.fade, '.hero__text'), '<')
      .fromTo(
        '.hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      );

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {};
}
