import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transition, transitionTimeline, transitions } from '../lib/animations';
import { DefaultView } from './DefaultView';
import Swiper, { Pagination } from 'swiper';

export class EventPostView extends DefaultView {
  namespace = 'event-post';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    const scrollto = this.container.querySelector('.event-hero__scrolldown');
    if (scrollto) {
      scrollto.addEventListener('click', () => {
        gsap.to(window, { scrollTo: { y: '.event-content', offsetY: 130 } });
        console.log('scroll');
      });
    }

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.event-hero__heading'))
      .add(transitionTimeline(transitions.fade, '.event-hero__mobile-date'), '<')
      .add(transitionTimeline(transitions.fade, '.event-hero__main-text'), '-=0.5')
      .add(transitionTimeline(transitions.fade, '.event-hero__aux-text'), '<')
      .add(transitionTimeline(transitions.fade, '.event-hero__button'), '<')
      .add(transitionTimeline(transitions.fade, '.event-hero__date'), '<')
      .add(transitionTimeline(transitions.fade, '.event-hero__props'), '<')
      .fromTo(
        '.event-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      );

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {};
}
