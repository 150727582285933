import A11yDialog from 'a11y-dialog';
import { submitForm } from '../lib/submitForm';
import {FormDialog} from './FormDialog';

export function registerStandaloneContactForm(container = document) {
  const form = container.querySelector('.js-contacts-form');
  if (!form) return;

  const successDialogElement = document.getElementById('success-dialog');
  const dialog = new A11yDialog(successDialogElement);

  submitForm(form, {
    onSubmit: () => {
      dialog.show();
      form
        .querySelectorAll('.input-group')
        .forEach((group) => group.classList.remove('is-focused'));
      setTimeout(() => dialog.hide(), 3000);
    },
  });
}

export function registerContactFormDialogs(container = document) {
  let dialogs = {};
  container.querySelectorAll('[data-contact-dialog]').forEach((btn) => {
    const id = btn.dataset.contactDialog;
    let dialog;
    if (!(id in dialogs)) {
      dialog = new FormDialog(id);
    } else {
      dialog = dialogs[id];
    }
    btn.addEventListener('click', () => dialog.open());
  });
}