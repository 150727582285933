import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transition, transitionTimeline, transitions } from '../lib/animations';
import { DefaultView } from './DefaultView';

export class CamerasView extends DefaultView {
  namespace = 'cameras';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.cameras-hero__heading'))
      .add(
        transitionTimeline(transitions.fade, '.cameras-hero__subheading'),
        '<'
      )
      .add(transitionTimeline(transitions.fade, '.cameras-hero__text'), '<')
      .fromTo(
        '.cameras-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      )
      .add(transitionTimeline(transitions.fade, '.cameras-weather'), '<');

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {};
}
