import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transition, transitionTimeline, transitions } from '../lib/animations';
import { DefaultView } from './DefaultView';
import Swiper, { Pagination } from 'swiper';
import { loadMorePosts } from '../lib/loadMorePosts';
import LazyLoad from 'vanilla-lazyload';

export class EventsView extends DefaultView {
  namespace = 'events';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    this.buttons = this.container.querySelectorAll('.events-hero__button');
    this.topEvents = this.container.querySelectorAll('.events-top');
    this.archives = this.container.querySelectorAll('.events-archive');

    this.buttons.forEach((btn) =>
      btn.addEventListener('click', () =>
        this.switchCategory(btn.dataset.category)
      )
    );

    const category = location.hash.slice(1) || 'news';
    this.switchCategory(category);

    new LazyLoad({
      elements_selector: '.event-card__lazy',
    });

    this.archives.forEach((archive) => {
      const list = archive.querySelector('.events-archive__list');
      const loadMoreButton = archive.querySelector('.events-archive__loadmore');

      if (list && loadMoreButton) {
        loadMorePosts(list, loadMoreButton, '.event-card__lazy', (elements) => {
          gsap.fromTo(elements, { autoAlpha: 0 }, { autoAlpha: 1 });
        });
      }
    });

    this.container
      .querySelector('.events-hero__select')
      .addEventListener('change', (e) => this.switchCategory(e.target.value));

    new Swiper('.events-top__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    new Swiper('.events-archive__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.events-hero__heading'))
      .fromTo(
        '.events-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      )
      .add(transitionTimeline(transitions.fade, '.events-hero__menu'), '<')
      .add(transitionTimeline(transitions.fade, '.events-top'), '<');

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  switchCategory = (name) => {
    if (!['news', 'photos', 'announcements'].includes(name)) {
      return;
    }

    if (name !== 'news') {
      location.hash = name;
    } else {
      location.hash = '';
    }

    this.buttons.forEach((btn) => {
      if (btn.dataset.category === name) {
        btn.classList.add('is-active');
      } else {
        btn.classList.remove('is-active');
      }
    });

    let current;
    let selected;
    this.topEvents.forEach((section) => {
      if (section.dataset.category === name) {
        selected = section;
      }

      if (!section.hidden) {
        current = section;
      }
    });

    if (current === selected) {
      return;
    }

    gsap.to(current, { autoAlpha: 0 }).then(() => {
      current.hidden = true;
      selected.hidden = false;
      gsap.to(selected, { autoAlpha: 1 });
    });

    this.archives.forEach((section) => {
      if (section.dataset.category === name) {
        section.hidden = false;
      } else {
        section.hidden = true;
      }
    });
  };

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {};

  animateDesktop = () => {};
}
