import { gsap } from 'gsap';

class Topbar {
  constructor() {
    this.btn = document.querySelector('.topbar__hamburger');
    this.menu = document.querySelector('.mobile-menu');

    this.btn.addEventListener('click', this.toggle);
  }

  open = () => {
    this.btn.classList.add('is-active');
    this.menu.classList.add('is-active');

    if (window.screen.availWidth < 768) {
      this.animateMobile();
    } else {
      this.animateTablet();
    }
  };

  close = () => {
    this.btn.classList.remove('is-active');
    this.menu.classList.remove('is-active');

    gsap
      .timeline()
      .fromTo(
        '.mobile-menu',
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        },
        {
          clipPath: 'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',

          ease: 'power3.in',
          duration: 0.4,
        }
      )
      .to('.mobile-menu', { visibility: 'hidden' });
  };

  toggle = () => {
    if (!this.btn.classList.contains('is-active')) {
      this.open();
    } else {
      this.close();
    }
  };

  animateMobile = () => {
    const tl = gsap.timeline().fromTo(
      '.mobile-menu',
      {
        clipPath: 'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',
      },
      {
        visibility: 'visible',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        ease: 'power3.in',
        duration: 0.4,
      }
    );

    const sections = document.querySelectorAll('.mobile-menu__section');
    sections.forEach((section) => {
      const main = section.querySelector('.mobile-menu__main-link');
      const links = section.querySelectorAll('.mobile-menu__link');
      const sectionTl = gsap.timeline();
      sectionTl.fromTo(
        main,
        {
          opacity: 0,
          x: '-100px',
        },
        {
          opacity: 1,
          x: 0,
          //ease: 'power3.in',
          duration: 0.3,
        }
      );
      links.forEach((link) => {
        const icon = link.querySelector('svg');
        sectionTl.fromTo(
          link,
          {
            x: '-100px',
            opacity: 0,
          },
          {
            opacity: 0.7,
            x: 0,
            //ease: 'power3.in',
            duration: 0.2,
          },
          '<0.1'
        );
      });

      tl.add(sectionTl, '=-0.2');
    });
  };

  animateTablet = () => {
    const tl = gsap.timeline().fromTo(
      '.mobile-menu',
      {
        clipPath: 'polygon(0 0, 0% 0, 0% 100%, 0% 100%)',
      },
      {
        visibility: 'visible',
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        ease: 'power3.in',
        duration: 0.4,
      }
    );

    const sections = document.querySelectorAll('.mobile-menu__section');
    sections.forEach((section) => {
      tl.fromTo(
        section,
        {
          x: '-300px',
        },
        {
          x: 0,
          //ease: 'power3.in',
          duration: 0.5,
        },
        '<0.2'
      );
      tl.fromTo(
        section,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          //ease: 'power3.in',
          duration: 1,
        },
        '<'
      );
    });
  };
}

export { Topbar };
