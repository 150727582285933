import { AjaxRequestError } from './errors';

/**
 * Loads data from WP Ajax action handler.
 *
 * NOTE: For add_action( 'wp_ajax_myaction', $ajax_object_query_handler );
 * 'myaction' will be the action name.
 *
 * @param {string} action Wordpress ajax action name
 * @param {object} params Other params passed to handler as $_POST['param_key']
 * @param files Files to send
 * @returns {Promise<object>} Returned text data in JSON format
 */
export async function wpAjax(action, params, files = []) {
  /* global wpParams */
  const formData = new FormData();
  for (const key in params) {
    formData.append(key, params[key]);
  }

  files.forEach((file) => formData.append('file', file));

  formData.append('action', action);

  const res = await fetch(wpParams.ajaxurl, {
    method: 'POST',
    body: formData,
  });

  const body = await res.json();

  if (!res.ok) {
    if (body.data.errorMessage) {
      throw new AjaxRequestError(body.data.errorMessage, res);
    } else {
      throw new AjaxRequestError(res.statusText, res);
    }
  }

  return body;
}
