import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transition, transitionTimeline, transitions } from '../lib/animations';
import { DefaultView } from './DefaultView';

export class EnduroView extends DefaultView {
  namespace = 'enduro';

  beforeEnter(data) {
    super.beforeEnter(data);
    this.container = data.next.container;

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.track-hero__heading'))
      .add(transitionTimeline(transitions.fade, '.track-hero__subheading'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__text'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__contact'), '<')
      .fromTo(
        '.track-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      );

    requestAnimationFrame(this.initAnimations);
  }

  beforeLeave(data) {
    super.beforeLeave(data);
  }

  initAnimations = () => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  };

  animateMobile = () => {
    this.container
      .querySelectorAll('.track-obstacles__item')
      .forEach((element) =>
        ScrollTrigger.create({
          trigger: element,
          start: 'top bottom-=200',
          animation: transition(transitions.fade, element),
        })
      );
  };

  animateDesktop = () => {
    this.container
      .querySelectorAll('.track-obstacles__item:nth-child(odd)')
      .forEach((element) =>
        ScrollTrigger.create({
          trigger: element,
          start: 'top bottom-=200',
          animation: gsap.fromTo(
            element,
            { autoAlpha: 0, x: -100 },
            { autoAlpha: 1, x: 0 }
          ),
        })
      );

    this.container
      .querySelectorAll('.track-obstacles__item:nth-child(even)')
      .forEach((element) =>
        ScrollTrigger.create({
          trigger: element,
          start: 'top bottom-=200',
          animation: gsap.fromTo(
            element,
            { autoAlpha: 0, x: 100 },
            { autoAlpha: 1, x: 0 }
          ),
        })
      );
  };
}
