// Custom animated cursor
// https://www.youtube.com/watch?v=TpwpAYi-p2w

function init() {
  const cursor = document.querySelector('.cursor');
  if (!cursor) return;

  window.addEventListener('mousemove', (e) => {
    cursor.hidden = false;
    cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
  });

  window.addEventListener('touchstart', (e) => {
    cursor.hidden = true;
  });

  window.addEventListener('mousedown', (e) => {
    cursor.classList.add('is-mousedown');
  });

  window.addEventListener('mouseup', () => {
    cursor.classList.remove('is-mousedown');
  });

  // TODO: detect actual mouse move to enable cursor (skip on touch events)
  // [interactive].mouseover -> cursor.classlist.add('active')
  // [interactive].mouseout -> cursor.classlist.remove('active')
}

init();
