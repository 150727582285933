import A11yDialog from 'a11y-dialog';
import Vivus from 'vivus';
import { submitForm } from '../lib/submitForm';

export class ServiceDialog {
  constructor(id) {
    this.dialogElement = document.getElementById(id);
    if (!this.dialogElement) return;

    this.services = this.dialogElement.querySelectorAll('.service-dialog__button');
    this.sidebars = this.dialogElement.querySelectorAll('.service-dialog__sidebar-item');
    this.serviceInput = this.dialogElement.querySelector('input[name="service"]');

    this.services.forEach((service, idx) => {
      service.addEventListener('mouseover', () =>
        this.highlightService(service, idx)
      );
      service.addEventListener('focus', () =>
        this.highlightService(service, idx)
      );

      service.addEventListener('click', () => {
        this.selectService(service);
      })
    });

    const form = this.dialogElement.querySelector('form');
    if (!form) return;

    const iconElement = form.querySelector('.form__success-icon');
    const icon = iconElement
      ? new Vivus(iconElement, { duration: 100, start: 'manual' }, () => {})
      : null;

    this.dialog = new A11yDialog(this.dialogElement);

    submitForm(form, {
      onSubmit: () => {
        form.classList.add('is-success');
        if (icon) {
          icon.play(1, () => {});
        }

        setTimeout(() => this.close(), 3000);
      },
    });
  }

  highlightService = (service, idx) => {
    this.services.forEach((service) => service.classList.remove('is-active'));
    this.sidebars.forEach((sidebar) => sidebar.classList.remove('is-active'));
    service.classList.add('is-active');
    this.sidebars[idx].classList.add('is-active');
  };

  selectService = (service) => {
    this.serviceInput.value = service.dataset.title;
    this.dialogElement.classList.add('is-service-selected');
  }

  close() {
    this.serviceInput.value = '';
    this.dialogElement.classList.remove('is-service-selected');
    if (this.dialog) this.dialog.hide();
  }

  open() {
    if (this.dialog) this.dialog.show();
  }
}
