/**
 * Parses HTML string into array no HTMLElements
 *
 * @param {String} html HTML representing any number of sibling elements
 * @return {[HTMLElement]}
 */
export function htmlToElements(html) {
  const template = document.createElement('template');
  template.innerHTML = html;
  return [...template.content.childNodes];
}