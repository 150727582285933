// fucus -> focus
// blur -> value.length === 0 ? blur : focus;

class FormGroup {
  /**
   *
   * @param {string} selectors
   * @param {HTMLElement|null} parent
   */
  constructor(selectors, parent = null) {
    if (parent) {
      this.inputs = Array.from(parent.querySelectorAll(selectors));
    } else {
      this.inputs = Array.from(document.querySelectorAll(selectors));
    }

    this.inputs.forEach((input) => {
      this.initElement(input);

      input.addEventListener('focus', this.focus);
      input.addEventListener('blur', this.blur);
    });
  }

  destroy() {
    this.inputs.forEach((input) => {
      input.removeEventListener('focus', this.focus);
      input.removeEventListener('blur', this.blur);
    });
  }

  initElement(input) {
    if (input.value.length > 0) {
      input?.parentElement?.classList.add('is-focused');
    } else {
      input?.parentElement?.classList.remove('is-focused');
    }
  }

  focus(event) {
    const input = event.target;
    input?.parentElement?.classList.add('is-focused');
  }

  blur(event) {
    const input = event.target;
    if (input.value.length === 0) {
      input?.parentElement?.classList.remove('is-focused');
    }
  }
}

export { FormGroup };
