class AjaxRequestError extends Error {
  constructor(message, response) {
    super(message);

    if (response) {
      this.url = response.url;
      this.status = response.status;
      this.statusText = response.statusText;
    }
  }
}

export { AjaxRequestError };
