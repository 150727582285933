import { wpAjax } from './wpAjax';
import LazyLoad from 'vanilla-lazyload';
import { htmlToElements } from './htmlToElements';

export function loadMorePosts(
  list,
  moreButton,
  lazySelector,
  onLoad = (elements) => {}
) {
  if (!moreButton || !list) return;

  const lazy = new LazyLoad({
    elements_selector: lazySelector,
  });

  const postType = moreButton.dataset.postsType;
  if (!postType) return;

  let paged = 2;
  let queryParams = {
    posts_per_page: parseInt(moreButton.dataset.postsPerPage || 6, 10),
    initial_offset: parseInt(moreButton.dataset.postsInitialOffset || 0, 10),
    paged,
  };

  const tag = moreButton.dataset.postsTag;
  if (tag) {
    queryParams.tag = tag;
  }

  function load() {
    moreButton.classList.add('is-loading');
    wpAjax(`get_posts_${postType}`, { ...queryParams, paged })
      .then((data) => {
        if (data.html.length > 0) {
          const elements = htmlToElements(data.html);
          elements.forEach((element) => list.appendChild(element));
          lazy.update();

          if (typeof onLoad === 'function') {
            onLoad(elements);
          }
        }
        moreButton.classList.remove('is-loading');

        if (parseInt(data.posts_left, 10) === 0) {
          moreButton.setAttribute('hidden', 'hidden');
        }

        paged++;
      })
      .catch(() => {
        moreButton.classList.remove('is-loading');
      });
  }

  moreButton.addEventListener('click', load);
}
