/**
 * Returns object of UTM parameters form query string.
 *
 * @returns {{}|{utm_term: string, utm_campaign: string, utm_medium: string, utm_source: string, utm_content: string}}
 */
export function getUTM() {
  if (!window.URLSearchParams) return {};

  const urlParams = new URLSearchParams(window.location.search);
  const keys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];

  let utms = {};
  keys.forEach((key) => {
    const val = urlParams.get(key);
    if (val) {
      utms[key] = val;
    }
  });

  return utms;
}
