import { gsap } from 'gsap';
import barba from '@barba/core';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import Swiper, { Pagination, Navigation } from 'swiper';
import { FormGroup } from './components/FormGroup';
import './components/cursor';
import './plugins/vh100';
import { LandingView } from './views/LandingView';
import { Animated } from './components/Animated';
import { TrackView } from './views/TrackView';
import { ContactForm } from './components/ContactForm';
import { Topbar } from './components/Topbar';
import { TracksView } from './views/TracksView';
import LazyLoad from 'vanilla-lazyload';
import { EnduroView } from './views/EnduroView';
import { CamerasView } from './views/CamerasView';
import { ServicesView } from './views/ServicesView';
import { EducationView } from './views/EducationView';
import { GazeboView } from './views/GazeboView';
import { table } from './components/table';
import { EventsView } from './views/EventsView';
import { EventPostView } from './views/EventPostView';
import { ContactsView } from './views/ContactsView';
import { FooterMap } from './components/FooterMap';
import {
  registerContactFormDialogs,
  registerStandaloneContactForm,
} from './components/contactForms';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, MotionPathPlugin);

const footerMap = new FooterMap();

new FormGroup('.contact-form__input');
new FormGroup('.input-group__input');
const topbar = new Topbar();

const lazy = new LazyLoad({
  elements_selector: '.lazy',
});

function swipers() {
  new Swiper('.numbers__swiper', {
    modules: [Pagination],
    slidesPerView: 'auto',
    spaceBetween: 40,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  new Swiper('.photos-slider__swiper', {
    modules: [Pagination, Navigation],
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
      1260: {
        spaceBetween: 40,
      },
    },
  });
}
swipers();
table();

barba.hooks.afterLeave((data) => {
  if (!data) return;

  // updates body classes
  let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
    match = regexp.exec(data.next.html);
  if (!match || !match[1]) {
    document.body.setAttribute('class', '');
  } else {
    document.body.setAttribute('class', match[1]);
  }
});

let animated = new Animated();
let contantForm = new ContactForm();

barba.hooks.beforeLeave((data) => {
  if (animated) animated.destroy();
  topbar.close();

  ScrollTrigger.getAll().forEach((t) => t.kill(false));
  ScrollTrigger.refresh();
  window.dispatchEvent(new Event('resize'));
});

barba.hooks.beforeEnter((data) => {
  footerMap.beforeEnterView(data.next).then();
});

barba.hooks.enter((data) => {
  const container = data.next.container;
  window.scrollTo(0, 0);
  animated = new Animated(container);
  contantForm = new ContactForm(container);
  new FormGroup('.input-group__input');
  lazy.update();
  swipers();
  table(container);
});

function registerAfter(container = document) {
  registerStandaloneContactForm(container)
  registerContactFormDialogs(container);
}
registerAfter();

barba.hooks.after((data) => {
  registerAfter(data.next.container)
})

barba.init({
  debug: true,
  prevent: ({ el }) => el.href && /(wp-admin)|(wp-login\.php)/.test(el.href),
  transitions: [
    {
      name: 'default',
      async leave(data) {
        await gsap.to(data.current.container, { opacity: 0 });
      },
      async after(data) {
        await gsap.from(data.next.container, { opacity: 0 });
      },
    },
  ],
  views: [
    new LandingView(),
    new TrackView(),
    new TracksView(),
    new EnduroView(),
    new ContactsView(),
    new CamerasView(),
    new ServicesView(),
    new EducationView(),
    new GazeboView(),
    new EventsView(),
    new EventPostView(),
  ],
});
