import Pristine from 'pristinejs/dist/pristine';
import { getUTM } from './getUTM';
import { wpAjax } from './wpAjax';
import Vivus from 'vivus'

Pristine.addValidator(
  'file-max-size',
  function (value, param1) {
    const files = Array.from(this.files);
    const maxSize = parseInt(param1, 10);

    return files.every((file) => file.size < maxSize);
  },
  'Размер файла слишком большой',
  5,
  false
);

function getFormInputs(form) {
  return Object.values(form).reduce((obj, field) => {
    if (field.name && field.value) {
      obj[field.name] = field.value;
    }
    return obj;
  }, {});
}

function getFormFiles(form) {
  return Object.values(form).reduce((arr, field) => {
    if (field.name && field.files) {
      arr = [...arr, ...field.files];
    }
    return arr;
  }, []);
}

function disableForm(form) {
  const fieldsets = form.querySelectorAll('fieldset');
  fieldsets.forEach((fieldset) =>
    fieldset.setAttribute('disabled', 'disabled')
  );

  const loader = form.querySelector('.form__loader');
  if (loader) loader.removeAttribute('hidden');
}

function enableForm(form) {
  const fieldsets = form.querySelectorAll('fieldset');
  fieldsets.forEach((fieldset) => fieldset.removeAttribute('disabled'));

  const loader = form.querySelector('.form__loader');
  if (loader) loader.setAttribute('hidden', 'hidden');
}

function handleSubmit(form) {
  const inputs = getFormInputs(form);
  const files = getFormFiles(form);
  const utms = getUTM();
  const params = { ...inputs, ...utms, path: window.location.pathname };
  return wpAjax('submit_form', { params: JSON.stringify(params) }, files);
}

/**
 * Submits form to wordpress ajax.
 *
 * @param {Element} form
 * @param {Object} options
 * @param {function([])} options.onSubmit
 */
export function submitForm(form, options = {}) {
  if (!form) return;

  const defaultOptions = {
    onSubmit: (data) => {},
  };
  options = { ...defaultOptions, ...options };

  const pristineConfig = {
    classTo: 'input-group',
    errorClass: 'has-error',
    successClass: 'is-ok',
    errorTextParent: 'input-group',
    errorTextTag: 'small',
    errorTextClass: 'input-group__error',
  };

  const pristine = new Pristine(form, pristineConfig);

  form.addEventListener('submit', (event) => {
    event.preventDefault();
    const valid = pristine.validate();
    if (valid) {
      disableForm(form);

      handleSubmit(form).then((data) => {
        enableForm(form);

        if (data.success) {
          form.reset();
          options.onSubmit(data);
        }
      });
    }
  });
}
