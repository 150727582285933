export function table(container = document) {
  container.querySelectorAll('.table').forEach((table) => {
    const ths = table.querySelectorAll('tr th');
    table.querySelectorAll('tr').forEach((row) => {
      row.querySelectorAll('td').forEach((td, i) => {
        if (i in ths) {
          td.dataset.title = ths[i].textContent;
        }
      });
    });
  });
}
