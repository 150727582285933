import { Loader } from '@googlemaps/js-api-loader';
import { mapStyle } from '../lib/mapStyle';
import { timeout } from '../lib/timeout';

export class FooterMap {
  constructor() {}

  init = async (namespace) => {
    /* global wpParams */
    await timeout(2000);
    const loader = new Loader({
      apiKey: wpParams.gmapsApiKey,
      version: 'weekly',
    });

    return loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('footer__map'), {
        center: { lat: 55.99675, lng: 37.645 },
        zoom: 15,
        disableDefaultUI: true,
        scrollwheel: false,
        zoomControl: false,
        styles: mapStyle,
        backgroundColor: '#282828',
      });

      this.pin = new google.maps.Marker({
        position: { lat: 55.9987, lng: 37.632 },
        map: null,
        icon: '/wp-content/themes/timber-theme/static/svg/map-pin.svg',
      });
    });
  };

  toggleMarker = (namespace) => {
    if (namespace === 'contacts') {
      this.pin.setMap(null);
    } else {
      this.pin.setMap(this.map);
    }
  };

  beforeEnterView = async (view) => {
    if (!this.map) {
      await this.init();
    }

    this.toggleMarker(view.namespace);
  };
}
