import Vivus from 'vivus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { transitions, transitionTimeline } from '../lib/animations';
import Swiper, { Pagination, Navigation, EffectFade, Controller } from 'swiper';
import { FormDialog } from '../components/FormDialog';
import { DefaultView } from './DefaultView';
import { scrolling } from '../lib/scrolling';

class TracksView extends DefaultView {
  namespace = 'tracks';

  beforeEnter(data) {
    super.beforeEnter(data);

    const container = data.next.container;
    this.container = container;

    const hero = container.querySelector('.track-hero');
    const heroBg = container.querySelector('.track-hero__bg');
    const heroInfo = container.querySelector('.track-hero__info');
    const heroNumbers = container.querySelector('.track-hero__numbers');
    const heroRibbons = container.querySelector('.track-hero__ribbons');
    const tracks = container.querySelector('.tracks-tracks');

    const numbers = [...container.querySelectorAll('.numbers__num svg')].map(
      (element) =>
        new Vivus(element, { duration: 50, start: 'manual' }, () => {})
    );
    this.numbers.forEach((num) => num.reset());

    this.snapAnimations = {
      init: () => {
        numbers.forEach((num) => num.reset());

        gsap
          .timeline()
          .to(heroNumbers, { autoAlpha: 0, duration: 0.3 }, 0)
          .to(heroRibbons, { autoAlpha: 0, duration: 0.3 }, 0)
          .to(heroBg, { autoAlpha: 1, duration: 0.3 }, 0)
          .to(heroInfo, { autoAlpha: 1, duration: 1 }, 0)
          .fromTo(
            container.querySelector('.track-hero__skewed-rectangle'),
            { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
            {
              clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
              duration: 1,
              ease: 'power1.inOut',
            },
            '-=0.5'
          )
          .add(
            transitionTimeline(
              transitions.fade,
              container.querySelector('.tracks-hero__cert')
            ),
            '<'
          );
      },
      numbers: () => {
        hero.hidden = false;
        numbers.forEach((num) => num.play(1, () => {}));

        gsap
          .timeline()
          .to(heroBg, { autoAlpha: 0, duration: 0.3 })
          .to(heroInfo, { autoAlpha: 0, duration: 1 }, 0)
          .to(tracks, { autoAlpha: 0, duration: 0.3 }, 0)
          .to(heroNumbers, { autoAlpha: 1, duration: 1 }, 0)
          .to(heroRibbons, { autoAlpha: 1, duration: 1 }, 0);
      },
      tracks: () => {
        hero.hidden = true;
        numbers.forEach((num) => num.reset());

        gsap.timeline().to(tracks, { autoAlpha: 1, duration: 0.3 }, 0);
      },
    };

    new Swiper('.mobile-benefits__swiper', {
      modules: [Pagination],
      slidesPerView: 'auto',
      spaceBetween: 15,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    new Swiper('.benefits__swiper .swiper', {
      modules: [Navigation, Pagination, EffectFade, Controller],
      loop: true,
      spaceBetween: 30,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },
    });

    gsap
      .timeline()
      .add(transitionTimeline(transitions.fade, '.track-hero__heading'))
      .add(transitionTimeline(transitions.fade, '.track-hero__subheading'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__text'), '<')
      .add(transitionTimeline(transitions.fade, '.track-hero__contact'), '<')
      .fromTo(
        '.track-hero__skewed-rectangle',
        { clipPath: 'polygon(0% 100%, 35% 100%, 35% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(65% 0%, 100% 0%, 35% 100%, 0% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        },
        '-=0.5'
      )
      .add(transitionTimeline(transitions.fade, '.tracks-hero__cert'), '<');
  }

  beforeLeave(data) {
    super.beforeLeave(data);

    this.numbers.forEach((num) => num && num.destroy());
  }

  animateDesktop = () => {
    const pulses = gsap
      .timeline({
        defaults: {
          autoAlpha: 1,
        },
      })
      .fromTo('.ball02', { autoAlpha: 0 }, { autoAlpha: 1 }, 0.25)
      .fromTo(
        '.benefits__item:nth-child(1)',
        { autoAlpha: 0 },
        { autoAlpha: 1 },
        0.25
      )
      .fromTo('.ball03', { autoAlpha: 0 }, { autoAlpha: 1 }, 1.2)
      .fromTo(
        '.benefits__item:nth-child(2)',
        { autoAlpha: 0 },
        { autoAlpha: 1 },
        1.2
      )
      .fromTo('.ball04', { autoAlpha: 0 }, { autoAlpha: 1 }, 3.2)
      .fromTo(
        '.benefits__item:nth-child(3)',
        { autoAlpha: 0 },
        { autoAlpha: 1 },
        3.2
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.benefits__list',
          scrub: 1,
          forward: true,
          start: 'top center',
          end: 'bottom bottom-=40%',
          // markers: true,
        },
      })
      .to('.ball01', { autoAlpha: 1, duration: 0.05 })
      .to(
        '.ball01',
        {
          motionPath: {
            path: '.theLine',
            align: '.theLine',
            alignOrigin: [0.5, 0.5],
          },
          duration: 4,
        },
        0
      )
      .add(pulses, 0);
  };
}

export { TracksView };
