import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class ContactForm {
  constructor(container = document) {
    this.container = container;
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(max-width: 767px)': this.animateMobile,
      '(min-width: 768px)': this.animateDesktop,
    });
  }

  animateMobile = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.container.querySelector('.contact-form__inner'),
          start: 'top bottom-=200',
        },
      })
      .fromTo(
        this.container.querySelectorAll('.contact-form__input-label'),
        { clipPath: 'polygon(0 0, 0% 0%, 0% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)',
          duration: 0.6,
          ease: 'power1.inOut',
        }
      );
  };

  animateDesktop = () => {
    const contactTl = gsap.timeline({
      scrollTrigger: {
        trigger: this.container.querySelector('.contact-form__inner'),
        start: 'top bottom-=200',
      },
    });
    contactTl
      .fromTo(
        this.container.querySelector('.contact-form__skewed-rectangle'),
        { clipPath: 'polygon(55% 0, 55% 0%, 25% 100%, 25% 100%)' },
        {
          clipPath: 'polygon(55% 0, 100% 0%, 70% 100%, 25% 100%)',
          duration: 1,
          ease: 'power1.inOut',
        }
      )
      .fromTo(
        this.container.querySelectorAll('.contact-form__input-label'),
        { clipPath: 'polygon(0 0, 0% 0%, 0% 100%, 0% 100%)' },
        {
          clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0% 100%)',
          duration: 0.6,
          ease: 'power1.inOut',
        }
      )
      .fromTo(
        this.container.querySelector('.contact-form__submit'),
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      );
  };
}

export { ContactForm };
